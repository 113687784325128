.welcome {
  overflow: hidden;
}
.welcome-image {
  margin: 0;
  /* width: 100%; */
  height: 100%;
  width: 100%;
}
.top {
  width: 600px;
  position: fixed;
  top: 80%;
  left: 50%;
  margin-top: -300px;
  margin-left: -300px;
}
/*
<h1>Welcome to the Jedi Archives</h1>
<button onClick={close}>Continue</button> */
